import store from '@/store';
store.commit('mobile/SET_MOBILE_CHECK');
// TODO rewrite workflows components to campaigns
const WorkflowsPage = () => import('@/views/workflows/WorkflowsPage');
const WorkflowsMyCampaignsPage = () => import('@/views/workflows/WorkflowsMyCampaignsPage');
const SmartSequencesPage = () => import('@/views/workflows/SmartSequencesPage');

const AddWorkflowPage = () => import('@/views/workflows/AddWorkflowPage');
const EditWorkflowPage = () => import('@/views/workflows/EditWorkflowPage');
const DuplicateWorkflowPage = () => import('@/views/workflows/DuplicateWorkflowPage');

const MobileAddWorkflowPage = () => import('@/mobile/campaigns/MobileAddWorkflowPage');
const MobilePerformActionItemEdit = () => import('@/mobile/campaigns/MobilePerformActionItemEdit');
const MobileEditWorkflowPage = () => import('@/mobile/campaigns/MobileEditWorkflowPage');
const isMobile = store.getters['mobile/isMobile'];

let campaigns = [];

const meta = {
  layout: 'DefaultLayout',
  hasSidebar: true,
};

const mobileMeta = {
  layout: 'MobileLayout',
  hasSidebar: true,
};

if (!isMobile) {
  campaigns = [
    {
      path: '/campaigns',
      name: 'campaigns',
      component: WorkflowsPage,
      redirect: { name: 'my-campaigns' },
      children: [
        {
          path: 'my-campaigns',
          name: 'my-campaigns',
          component: WorkflowsMyCampaignsPage,
          meta,
        },
      ],
    },
    {
      path: '/campaigns/duplicate',
      name: 'duplicate-campaign',
      component: DuplicateWorkflowPage,
      meta,
    },
    {
      path: '/campaign/add',
      name: 'add-campaign',
      component: AddWorkflowPage,
      meta,
    },
    {
      path: '/campaign/edit/:id',
      name: 'edit-campaign',
      component: EditWorkflowPage,
      meta,
    },
    {
      path: '/smart-sequences',
      name: 'smart-sequences',
      component: SmartSequencesPage,
      meta: {
        layout: 'EmptyLayout',
      },
    },
  ];
} else {
  campaigns = [
    {
      path: '/campaigns',
      name: 'campaigns',
      component: WorkflowsPage,
      meta: {
        layout: 'MobileLayout',
      },
      redirect: { name: 'my-campaigns' },
      children: [
        {
          path: 'my-campaigns',
          name: 'my-campaigns',
          component: WorkflowsMyCampaignsPage,
          meta: mobileMeta,
        },
      ],
    },
    {
      path: '/campaign/edit/:id',
      name: 'edit-campaign',
      component: MobileEditWorkflowPage,
      meta: mobileMeta,
      children: [
        {
          path: '/campaign/edit/step',
          name: 'step-edit',
          component: MobilePerformActionItemEdit,
          meta: mobileMeta,
        },
      ],
    },
    {
      path: '/campaign/add',
      name: 'add-campaign',
      component: MobileAddWorkflowPage,
      meta: mobileMeta,
      children: [
        {
          path: '/campaign/add/step',
          name: 'add-step-edit',
          component: MobilePerformActionItemEdit,
          meta: mobileMeta,
        },
      ],
    },
  ];
};

export default campaigns;
