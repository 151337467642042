<template>
  <div class="workspace-users">
    <default-button
      v-if="isAdmin"
      class="workspace-users__invite-button"
      title="Invite"
      icon-name="bx-plus"
      color="transparent"
      form="rectangle"
      size="s"
      @action="modals.invite = true"
    />
    <div class="workspace-users__list">
      <users-user
        v-for="user in account.users"
        :key="user._id"
        class="workspace-users__list-item"
        :user="user"
      />
    </div>
    <users-invite-modal
      :show-modal.sync="modals.invite"
    />
  </div>
</template>
<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import UsersUser from '@/components/preferencesPage/workspace/users/user/UsersUser';
  import UsersInviteModal from '@/components/preferencesPage/workspace/users/UsersInviteModal';
  import { mapGetters } from 'vuex';

  export default {
    name: 'WorkspaceUsers',
    components: {
      DefaultButton,
      UsersUser,
      UsersInviteModal,
    },
    data () {
      return {
        modals: {
          invite: false,
          isAdmin: false,
        },
        isAdmin: false,
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
        user: 'user/user',
      }),
    },
    mounted () {
      this.isAdmin = this.account?.admins.includes(this.user._id);
    },
  };
</script>
<style lang="scss">
.workspace-users {
  position: relative;
  &__invite-button {
    position : absolute;
    top: -44px;
    right: 0;
  }

  &__list {
    &:first-child {
      margin-bottom: 0;
    }

    &-item {
      padding: 16px;
      margin-bottom: 8px;
      border-radius: $border-radius-small;
      border: $border;
    }
  }
}
</style>
