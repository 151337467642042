<template>
  <portal
    to="body-end"
  >
    <transition
      name="vm-transition--overlay"
    >
      <modal
        v-show="!keepModalAlive || show"
        :key="keyModal"
        :name="name"
        :width="width"
        :height="height"
        :classes="modalClass"
        :adaptive="true"
        @closed="handleClose"
        @before-close="beforeClose"
      >
        <div
          class="modal"
          tabindex="-1"
          :style="modalStyle"
          @keyup.enter="$emit('send', inputText)"
        >
          <div class="modal__top-buttons">
            <slot name="topButtons" />
            <default-button
              form="square"
              size="m"
              title-color="black"
              icon-name="bx-x"
              color=""
              :has-paddings="false"
              @action="handleClose"
            />
          </div>
          <header class="modal__header mb-2">
            <p class="modal__title">
              {{ title }}
            </p>
            <slot name="title" />
          </header>
          <slot name="modalText" />
          <p
            v-if="modalText.length && show"
            ref="modalText"
            class="modal__text text"
          >
            {{ setLinks(modalText) }}
          </p>
          <slot />
          <slot name="actions" />
          <footer
            v-if="showButtons"
            class="modal__buttons"
          >
            <p
              v-if="isCanBeSend"
              :class="['primary-btn', { 'primary-btn_error': error }]"
              :style="primaryButtonStyle"
              @click="send"
            >
              {{ primaryButtonText }}
            </p>
            <p
              v-if="isCancelable"
              :class="['primary-btn  primary-btn_gray']"
              @click="handleClose"
            >
              {{ cancelButtonText }}
            </p>
          </footer>
        </div>
      </modal>
    </transition>
  </portal>
</template>

<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  // eslint-disable-next-line prefer-regex-literals
  const urlRegex = new RegExp(
    '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$'
  );
  // eslint-disable-next-line prefer-regex-literals
  const httpRegex = new RegExp(/^http(s)?:\/\//);

  export default {
    name: 'DefaultModal',
    components: {
      DefaultButton,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      modalClass: {
        type: String,
        default: 'component',
      },
      isCancelable: {
        type: Boolean,
        default: true,
      },
      keepModalAlive: {
        type: Boolean,
        default: false,
      },
      showButtons: {
        type: Boolean,
        default: true,
      },
      error: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '',
      },
      modalText: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: 'inputModal',
      },
      primaryButtonText: {
        type: String,
        default: 'Confirm',
      },
      cancelButtonText: {
        type: String,
        default: 'Cancel',
      },
      errorText: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      height: {
        type: String,
        default: '420px',
      },
      width: {
        type: String,
        default: '450px',
      },
      primaryButtonStyle: {
        type: Object,
        default: null,
      },
      modalStyle: {
        type: Object,
        default: null,
      },
      isCanBeSend: {
        type: Boolean,
        default: true,
      },
      keyModal: {
        type: String,
        default: 'defaultModal',
      },
    },
    data () {
      return {
        inputText: this.text,
      };
    },
    watch: {
      show (show) {
        show ? this.$modal.show(this.name) : !this.keepModalAlive && this.$modal.hide(this.name);
      },
    },
    methods: {
      beforeClose (e) {
        if (this.keepModalAlive) {
          this.handleClose();
          this.$store.dispatch('OPEN_MODAL', { isOpen: false, name: this.name });
          e.cancel();
        }
      },
      handleClose () {
        this.$emit('close');
        this.$emit('update:show', false);
      },
      send () {
        this.$emit('send', this.inputText);
      },
      setLinks (text) {
        const links = text.match(urlRegex) || [];

        links.forEach((link) => {
          const checkLinkParts = link.split('.');
          if (!checkLinkParts.includes('')) {
            // create link
            const a = document.createElement('a');
            let href = link;

            if (!href.match(httpRegex)) {
              href = 'https://' + href.replace('www.', '');
            }

            a.setAttribute('href', href);
            a.setAttribute('target', '_blank');
            a.innerHTML = `<span class="link-text">${link}</span>`;

            text = text.replace(link, a.outerHTML);
          }
        });

        setTimeout(() => {
          if (this.$refs.modalText) {
            this.$refs.modalText.innerHTML = text;
          }
        });

        return text;
      },
    },
  };
</script>

<style lang="scss">
.link-text {
  color: $linkColor !important;
}
</style>
<style lang="scss" scoped>

  .update-label {
    &__colors-block {
      position: relative;
    }
  }

  .vm-transition--overlay-enter-active,
.vm-transition--overlay-leave-active {
  transition: all 250ms;
}

.vm-transition--overlay-enter,
.vm-transition--overlay-leave-active {
  opacity: 0;
}

.vm-transition--modal-enter-active,
.vm-transition--modal-leave-active {
  transition: all 400ms;
}

.vm-transition--modal-enter,
.vm-transition--modal-leave-active {
  opacity: 0;
  transform: translateY(-20px);
}

.vm-transition--default-enter-active,
.vm-transition--default-leave-active {
  transition: all 2ms;
}

.vm-transition--default-enter,
.vm-transition--default-leave-active {
  opacity: 0;
}
</style>
