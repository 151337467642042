import { getLabels } from '@/api/labelsMethods';

const state = () => ({
  labelsList: null,
  spaceLabelsList: null,
  templateLabelsList: null,
});

const getters = {
  labelsList: state => {
    return state.labelsList;
  },
  spaceLabelsList: state => {
    return state.spaceLabelsList;
  },
  templateLabelsList: state => {
    return state.templateLabelsList;
  },
};

const mutations = {
  SET_LABELS_LIST (state, payload) {
    state.labelsList = payload;
  },
  SET_SPACE_LABELS_LIST (state, payload) {
    state.spaceLabelsList = payload;
  },
  SET_TEMPLATE_LABELS_LIST (state, payload) {
    state.templateLabelsList = payload;
  },
};

const actions = {
  async SET_LABELS_LIST ({ commit }, payload) {
    const labels = payload || await getLabels();
    commit('SET_LABELS_LIST', labels);
    return labels;
  },
  async SET_SPACE_LABELS_LIST ({ commit }, payload) {
    const labels = payload || await getLabels('space');
    commit('SET_SPACE_LABELS_LIST', labels);
    return labels;
  },
  async SET_TEMPLATE_LABELS_LIST ({ commit }, payload) {
    const labels = payload || await getLabels('template');
    commit('SET_TEMPLATE_LABELS_LIST', labels);
    return labels;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
