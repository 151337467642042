import store from '@/store';
const ReportsPage = () => import('@/views/ReportsPage');
const isMobile = store.getters['mobile/isMobile'];

let reports = [];

if (!isMobile) {
  reports = [
    {
      path: '/reports',
      name: 'reports',
      component: ReportsPage,
      meta: {
        layout: 'DefaultLayout',
        hasSidebar: true,
      },
    },
  ];
} else {
  reports = [
    {
      path: '/reports',
      name: 'reports',
      component: ReportsPage,
      meta: {
        layout: 'MobileLayout',
        hasSidebar: true,
      },
    },
  ];
};
export default reports;
