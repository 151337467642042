import { useHttp } from '@/middleware/httpMiddleware';

async function getAllWorkers () {
  const data = await useHttp(
    '/workers/fetch',
    'GET'
  );

  return data.payload;
}

async function deleteWorker (workerId) {
  const data = await useHttp(
    `/workers/${workerId}/delete`,
    'POST'
  );

  return data.payload;
}

async function updateWorker (worker) {
  const data = await useHttp(
      `/workers/${worker.id}/update`,
      'POST',
      {
        worker_object: JSON.stringify(worker),
      }
  );
  return data.payload;
}

async function getFreeWorkers () {
  const data = await useHttp(
    '/workers/free',
    'GET'
  );
  return data.payload;
}

export { getAllWorkers, deleteWorker, updateWorker, getFreeWorkers };
