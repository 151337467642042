import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderMixin } from '@/mixins/loaderMixin';
import { mapGetters } from 'vuex';
import LoaderFullPage from '@/components/LoaderFullPage';
export const fullPageLoader = {
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
      account: 'account/account',
    }),
  },
  components: {
    LoaderFullPage,
  },
  watch: {
    loaded: function (value) {
      const color = this.account?.config?.color ? this.account.config.color : 'var(--primary-cl)';
      if (!value) {
        this.loader = this.$loading.show({
          color,
          isFullPage: true,
          height: 90,
          width: 90,
          backgroundColor: 'var(--loader-bg)',
          loader: 'dots',
          lockScroll: true,
          transition: 'fade',
        },
        {
          before: this.$createElement('LoaderFullPage'),
        });
      } else {
        this.loader.hide();
      }
    },
  },
};
