import { useHttp } from '@/middleware/httpMiddleware';

async function getLabels (type = 'profile') {
  const data = await useHttp(
    '/labels/fetch',
    'GET',
    null,
    {
      label_type: type,
    }
  );
  return data.payload;
}
async function createLabel (name, color, type = 'profile') {
  const data = await useHttp(
    '/labels/create',
    'POST',
    {
      name,
      color,
      label_type: type,
    }
  );
  return data.payload;
}

async function deleteLabel (id) {
  const data = await useHttp(
    `/labels/${id}/delete`,
    'POST',
    null
  );
  return data.payload;
}
export { getLabels, createLabel, deleteLabel };
