<template>
  <div>
    <basic-avatar
      class="mb-2"
      :avatar="avatarImg"
      @change="uploadAvatarHandler"
    />
    <basic-form />
  </div>
</template>
<script>
  import BasicAvatar from '@/components/preferencesPage/user/basic/BasicAvatar';
  import BasicForm from '@/components/preferencesPage/user/basic/BasicForm';
  import { uploadAvatar } from '@/api/userMethods.js';
  import { mapGetters } from 'vuex';

  export default {
    components: { BasicAvatar, BasicForm },
    data () {
      return {
        avatarImg: null,
      };
    },
    computed: {
      ...mapGetters({
        user: 'user/user',
      }),
    },
    beforeMount () {
      this.avatarImg = this.user.avatar ? this.user.avatar + '?no_cache=true' : null;
    },
    methods: {
      async uploadAvatarHandler (e) {
        try {
          const file = e.target.files[0];
          this.avatarImg = URL.createObjectURL(file);
          await uploadAvatar(file);

          this.$noty.success('User avatar has been successfully changed');
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
    },
  };
</script>
