function capitalize (string) {
  return string[0].toUpperCase() + string.slice(1);
}
const capitalizeFilterMixin = {
  filters: {
    capitalize: function (value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  methods: {
    capitalize: function (value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};

export { capitalize, capitalizeFilterMixin };
