
const AuthPage = () => import('@/views/auth/AuthPage');
const SignUpPage = () => import('@/views/auth/SignUpPage');
const CallPage = () => import('@/views/auth/CallPage');
const ForgotPasswordPage = () => import('@/views/auth/ForgotPasswordPage');
const RestorePasswordPage = () => import('@/views/auth/RestorePasswordPage');

const auth = [
  {
    path: '/auth',
    name: 'auth',
    redirect: '/auth/sign-in',
  },
  {
    path: '/auth/sign-in',
    name: 'sign-in',
    component: AuthPage,
    meta: {
      layout: 'ClearLayout',
    },
  },
  {
    path: '/auth/sign-up',
    name: 'sign-up',
    component: SignUpPage,
    meta: {
      layout: 'SignUpLayout',
    },
  },
  {
    path: '/auth/call',
    name: 'sign-up',
    component: CallPage,
    meta: {
      layout: 'SignUpLayout',
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordPage,
    meta: {
      layout: 'ClearLayout',
    },
  },
  {
    path: '/auth/restore-password',
    name: 'restore-password',
    component: RestorePasswordPage,
    meta: {
      layout: 'ClearLayout',
    },
  },

];

export default auth;
