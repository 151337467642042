import store from '@/store';
const PeoplePage = () => import('@/views/PeoplePage');
const MobilePeoplePage = () => import('@/mobile/inbox/MobilePeoplePage');
const MobilePeopleChat = () => import('@/mobile/inbox/MobilePeopleChat');
const MobilePeopleProfile = () => import('@/mobile/inbox/MobilePeopleProfile');

const isMobile = store.getters['mobile/isMobile'];
let inbox = [];

const mobileMeta = {
  layout: 'MobileLayout',
  hasSidebar: true,
};

if (!isMobile) {
  inbox = [
    {
      path: '/inbox/:profileId?',
      name: 'inbox',
      component: PeoplePage,
      meta: {
        layout: 'DefaultLayout',
        hasSidebar: true,
      },
    },
  ];
} else {
  inbox = [
    {
      path: '/inbox/',
      name: 'inbox',
      component: MobilePeoplePage,
      meta: mobileMeta,
      children: [
        {
          path: '/inbox/:profileId',
          name: 'inbox-chat',
          component: MobilePeopleChat,
          meta: mobileMeta,
        },
        {
          path: '/inbox/info/:profileId',
          name: 'inbox-info',
          component: MobilePeopleProfile,
          meta: mobileMeta,
        },
      ],
    },
  ];
};
export default inbox;
