<template>
  <header class="pl-3 pr-3">
    <h1 class="text_l text_weight_accent">
      {{ headerTitle }}
    </h1>
    <default-list-item
      class="mt-2 mb-2"
      :title="listItemTitle"
      :avatar-photo-link="listItemAvatar"
      :text="listItemText"
      text-color="secondary"
    />
  </header>
</template>
<script>
  import DefaultListItem from '@/components/base/uiKit/DefaultListItem';
  export default {
    components: {
      DefaultListItem,
    },
    props: {
      headerTitle: {
        type: String,
        required: true,
      },
      listItemTitle: {
        type: String,
        required: true,
      },
      listItemText: {
        type: String,
        required: true,
      },
      listItemAvatar: {
        type: String,
        required: true,
      },
    },
  };
</script>
