import { getTemplates } from '@/api/TemplateMethods';

const state = () => ({
  templateList: [],
});

const getters = {
  templateList: state => {
    return state.templateList;
  },
};

const mutations = {
  SET_TEMPLATE_LIST (state, payload) {
    state.templateList = payload;
  },
  UPDATE_TEMPLATE_LIST (state, payload) {
    const templateList = state.templateList.slice(0);
    const existingIndex = templateList.findIndex(template => template._id === payload._id);
    if (state.templateList._id === payload._id) {
      state.templateList = payload;
    }
    if (existingIndex > -1) {
      templateList.splice(existingIndex, 1, payload);
    } else {
      templateList.push(payload);
    }
    state.templateList = templateList;
  },
  DELETE_TEMPLATES (state, deleteIDs) {
    state.templateList = state.templateList.filter((template) => {
      return !deleteIDs.includes(template._id);
    });
  },
};

const actions = {
  async SET_TEMPLATE_LIST ({ commit }, payload) {
    const templates = payload || await getTemplates();
    commit('SET_TEMPLATE_LIST', templates);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
