import { getCredentialIntegrations } from '@/api/credentialsMethods';

export default {
  namespaced: true,
  state: {
    integrationsList: [],
  },
  getters: {
    integrationsList (state) {
      return state.integrationsList;
    },
  },
  mutations: {
    SET_INTEGRATIONS_LIST (state, integrationsList) {
      state.integrationsList = integrationsList;
    },
  },
  actions: {
    async SET_INTEGRATIONS_LIST ({ commit }, credentialId) {
      const integrations = await getCredentialIntegrations(credentialId);
      commit('SET_INTEGRATIONS_LIST', integrations);
    },
  },
};
