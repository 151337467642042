<template>
  <div>
    <p class="text_m color_secondary mb-2">
      When you delete your workspace, you lose access to {{ user.config.name }} account services, and we permanently delete your personal data. You can cancel the deletion for 14 days.
    </p>
    <div
      ref="vldParent"
      class="vld-parent"
    >
      <div
        class="flex-row flex-gap-8 cursor-pointer mb-2"
        @click.stop="formData.confirmation = !formData.confirmation"
      >
        <checkbox :is-active="formData.confirmation" />
        <p class="text_m color_tertiary">
          Confirm that I want to delete my workspace.
        </p>
      </div>
      <div class="flex-row flex-gap-8">
        <a
          v-if="(user.brand === 'buzz')"
          href="https://www.buzz.ai/terms/terms-conditions"
          target="_blank"
        >
          <default-button
            title="Learn more"
            color="neutral"
            form="rectangle"
            size="m"
          />
        </a>
        <default-button
          v-else
          title="Learn more"
          color="neutral"
          form="rectangle"
          size="m"
          @action="$router.push({ name: 'terms-of-use' })"
        />
        <default-button
          title="Delete my workspace"
          color="danger"
          form="rectangle"
          size="m"
          :is-disabled="!formData.confirmation"
          @action="deleteCurrentSpace"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import Checkbox from '@/components/base/Checkbox';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { mapGetters } from 'vuex';
  import { loaderMixin } from '@/mixins/loaderMixin';
  import { deleteSpace } from '@/api/spaceMethods';

  export default {
    components: {
      Checkbox,
      DefaultButton,
    },
    mixins: [loaderMixin],
    data () {
      return {
        formData: {
          confirmation: false,
        },
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
        user: 'user/user',
      }),
    },
    methods: {
      async deleteCurrentSpace () {
        this.loaded = false;
        try {
          await deleteSpace(this.$route.params.id);
          this.$store.commit('account/DELETE_SPACE', this.account);
          this.$noty.success('Current space has been successfully deleted');
          window.location.href = '/';
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
    },
  };
</script>
